<div class="payment-success-container">
    <div *ngIf="paymentSuccess && orderDetails; else errorTemplate">
      <h2>Thank You for Your Payment!</h2>
      <p>Your order <strong>#{{ orderDetails.id }}</strong> has been successfully processed.</p>
      <p><strong>Order Total:</strong> {{ orderDetails.totalAmount }} EUR</p>
  
      <div class="order-summary">
        <h3>Order Summary</h3>
        <ul>
          <li *ngFor="let item of orderDetails.items">
            {{ item.quantity }} x {{ item.productName }} - {{ item.price }} EUR
          </li>
        </ul>
      </div>
  
      <button mat-raised-button color="primary" (click)="viewOrderDetails()" disabled>View Order Details</button>
      <button mat-raised-button color="accent" (click)="printReceipt()">Print Receipt</button>
    </div>
  
    <!-- Error Handling -->
    <ng-template #errorTemplate>
      <h2>Payment Not Completed</h2>
      <p>{{ errorMessage }}</p>
      <button mat-raised-button color="primary" (click)="goToCheckout()">Return to Checkout</button>
    </ng-template>
  </div>
  