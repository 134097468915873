import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-pass-recovery',
  standalone: true,
  imports: [
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
  ],
  templateUrl: './pass-recovery.component.html',
  styleUrl: './pass-recovery.component.scss',
})
export class PassRecoveryComponent {
  model: any = {
    email: '',
  };

  passwordMismatch: boolean = false;

  constructor(private authService: AuthService, private router: Router) {}

  onSubmit() {
    // this.authService.register(this.model.email, this.model.password).subscribe({
    //   next: (res) => {
    //     if (res) {
    //       this.router.navigate(['/profile']);
    //       console.log('Register successful');
    //     } else {
    //       console.log('Register failed');
    //     }
    //   },
    //   error: (error) => {
    //     console.error('Register failed', error);
    //   },
    // });
  }
}
