import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { DeliveryOption } from '../cart.component';
import { getClosestDate } from '../../../shared';

@Component({
  selector: 'app-delivery-options',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './delivery-options.component.html',
  styleUrl: './delivery-options.component.scss',
})
export class DeliveryOptionsComponent {
  @Output() optionSelected = new EventEmitter<string>();

  deliveryOptions: DeliveryOption[] = [
    {
      name: 'Paņemšu pats birojā',
      date: 'Piegādes datums tiks precizēts pēc pasūtījuma apstrādes',
      price: 0,
    },
    {
      name: 'DPD Pickup punkti',
      date: 'Piegādes datums tiks precizēts pēc pasūtījuma apstrādes',
      price: 1.59,
    },
    {
      name: 'DPD kurjers',
      date: 'Piegādes datums tiks precizēts pēc pasūtījuma apstrādes',
      price: 5,
    },
  ];

  selectOption(option: any): void {
    this.optionSelected.emit(option);
  }
}
