import { CommonModule, Location } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  BasketItem,
  BasketService,
} from '../../services/basket/basket.service';
import { DeliveryOptionsComponent } from './delivery-options/delivery-options.component';
import { MatDivider } from '@angular/material/divider';
import { getClosestDate } from '../../shared';
import { Router } from '@angular/router';

export interface DeliveryOption {
  name: string;
  date: string;
  price: number;
}

@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [CommonModule, FormsModule, DeliveryOptionsComponent, MatDivider],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss',
})
export class CartComponent {
  basketItems: BasketItem[] = [];
  total: number = 0;
  todayDate: string = new Date().toLocaleDateString('lv-LV');
  deliveryOption: DeliveryOption = {
    name: 'Paņemšu pats birojā',
    date: getClosestDate(),
    price: 0,
  };
  quantities = Array.from({ length: 10 }, (_, i) => i + 1);
  isDropdownOpen: boolean = false;

  constructor(
    private basketService: BasketService,
    private location: Location,
    private router: Router
  ) {
    this.basketService.setTitle('Grozs');
  }

  ngOnInit(): void {
    this.basketService.getBasket().subscribe((items) => {
      this.basketItems = items;
      this.total = this.basketService.getTotal();
    });
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onDeliveryOptionSelected(option: any): void {
    this.deliveryOption = option;
    this.isDropdownOpen = false;
  }

  removeItem(productId: string): void {
    this.basketService.removeItem(productId);
  }

  updateQuantity(productId: string, event: Event): void {
    this.removeRedirect(event);
    const inputElement = event.target as HTMLInputElement;
    const quantityAsNumber = parseInt(inputElement.value, 10);
    if (quantityAsNumber <= 0) {
      this.removeItem(productId);
    } else {
      this.basketService.updateItemQuantity(productId, quantityAsNumber);
    }
  }

  removeRedirect(event: Event) {
    if (event) event.stopPropagation();
  }

  back() {
    this.location.back();
  }

  submit() {
    this.router.navigate(['/shop/checkout/order']);
  }

  // Listen for clicks anywhere in the document
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const clickedInside =
      target.closest('.delivery-section') ||
      target.closest('.dropdown-content');

    if (!clickedInside) {
      this.isDropdownOpen = false;
    }
  }
}
