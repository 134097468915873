import { CommonModule } from '@angular/common';
import { AfterViewInit, Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { State, StateService } from '../../../../services/state/state.service';
import { OrderPayload } from '../../../../models/order.model';
import { BasketService } from '../../../../services/basket/basket.service';
import { OrderService } from '../../../../services/order/order.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment',
  standalone: true,
  imports: [
    CommonModule,
    MatRadioModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
  ],
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss',
})
export class PaymentComponent implements AfterViewInit {
  paymentForm: FormGroup;
  paymentOptions = [
    { value: 'bank', label: 'Internetbanka' },
    { value: 'card', label: 'Ar bankas karti' },
    { value: 'prepayment', label: 'Priekšapmaksas rēķins' },
    { value: 'office', label: 'ARSIKO birojā' },
  ];
  state!: State;

  selectedPaymentMethod: string | null = null;

  constructor(
    private fb: FormBuilder,
    private stateService: StateService,
    private basketService: BasketService,
    private orderService: OrderService,
    private router: Router
  ) {
    this.paymentForm = this.fb.group({
      paymentMethod: ['', Validators.required],
      cardDetails: this.fb.group({
        cardNumber: ['', [Validators.required, Validators.pattern('\\d{16}')]],
        expiryDate: ['', Validators.required],
        cvv: ['', [Validators.required, Validators.pattern('\\d{3}')]],
        cardHolder: ['', Validators.required],
      }),
      bankDetails: this.fb.group({
        bank: ['', Validators.required],
      }),
    });
    this.stateService.currentState.subscribe((state) => {
      this.state = state;
    });

    // Disable card and bank forms initially
    this.toggleAdditionalFields(null);
  }

  ngAfterViewInit(): void {
    this.onPaymentMethodChange('bank');
  }

  toggleAdditionalFields(method: string | null) {
    this.selectedPaymentMethod = method;

    if (method === 'card') {
      this.paymentForm.get('cardDetails')?.enable();
      this.paymentForm.get('bankDetails')?.disable();
    } else if (method === 'bank') {
      this.paymentForm.get('bankDetails')?.enable();
      this.paymentForm.get('cardDetails')?.disable();
    } else {
      this.paymentForm.get('cardDetails')?.disable();
      this.paymentForm.get('bankDetails')?.disable();
    }
  }

  onPaymentMethodChange(method: string) {
    this.paymentForm.get('paymentMethod')?.setValue(method);
    this.toggleAdditionalFields(method);
  }

  isFormValid(): boolean {
    if (
      this.selectedPaymentMethod === 'card' &&
      this.paymentForm.get('cardDetails')?.invalid
    ) {
      return false;
    }
    if (
      this.selectedPaymentMethod === 'bank' &&
      this.paymentForm.get('bankDetails')?.invalid
    ) {
      return false;
    }
    return this.paymentForm.valid;
  }

  submit() {
    if (this.isFormValid()) {
      console.log('Form Submitted:', this.paymentForm.value);
      if (this.state.customerInfo && this.state.selectedDeliveryMethod) {
        console.log(
          'Creating order',
          this.state.customerInfo,
          this.state.selectedDeliveryMethod
        );
        const paymentData: OrderPayload = {
          items: this.basketService.getItems(),
          totalAmount: this.basketService.getTotal(),
          customerInfo: this.state.customerInfo,
          deliveryInfo: this.state.selectedDeliveryMethod,
          paymentInfo: this.paymentForm.value,
        };
        this.orderService.createOrder(paymentData).subscribe({
          next: (data) => {
            console.log(data);
            if (data.url) {
              // this.router.navigateByUrl(data.url);
              window.location.href = data.url;
            }
          },
          error: (err) => {
            console.log(err);
          },
        });
      }
    } else {
      console.log('Form is invalid');
    }
  }

  get cardDetails(): FormGroup {
    return this.paymentForm.get('cardDetails') as FormGroup;
  }

  get bankDetails(): FormGroup {
    return this.paymentForm.get('bankDetails') as FormGroup;
  }
}
