<div class="w-full py-4 h-full bg-neutral-50 flex flex-col">
    <!-- <section class="flex justify-center">
        <img src="assets/banner.png" alt="Sale Banner" class="w-1/2">
    </section> -->
    @if(loading){
    <div class="mx-auto mt-36 wrap w-1/4 justify-center items-center text-center">
        <div class="loader"></div>
    </div>
    }@else{
    @if(categoryTree && categoryTree.length){
    <app-category-grid [categories]="categoryTree" class="mt-10"></app-category-grid>
    } @else {
    <div class="mx-auto mt-36 w-1/2 text-xl wrap justify-center items-center text-center">Vietne pašlaik nav
        pieejama, ARSIKO katalogs netiek ielādēts. <br>Lūdzu, mēģiniet vēlreiz vēlāk.</div>
    }}
</div>