import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { StateService } from '../../../services/state/state.service';
import { Subscription } from 'rxjs';
import { TecdocService } from '../../../services/tecdoc/tecdoc.service';
import { extractYear } from '../../../shared';

@Component({
  selector: 'app-model',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './model.component.html',
  styleUrl: './model.component.scss',
})
export class ModelComponent implements OnDestroy {
  id!: string | null;
  vehicleType!: string | null;
  state: any;
  subscriptions: Subscription = new Subscription();

  constructor(
    private activatedRoute: ActivatedRoute,
    private stateService: StateService,
    private tecdocService: TecdocService
  ) {}
  ngOnInit() {
    this.activatedRoute.params.subscribe((data) => {
      this.id = data['modelId'];
      this.vehicleType = data['vehicleType'];
      this.subscriptions.add(
        this.stateService.currentState.subscribe((state) => {
          this.state = state;
        })
      );
      this.stateService.updateState({
        ...this.state,
        isModel: true,
        modelId: this.id,
        vehicleType: this.vehicleType,
        heading: ''
      });
      this.tecdocService
        .getCarDetails({ modelId: this.id, type: this.vehicleType })
        .subscribe({
          next: (response) => {
            const data = response[0];
            this.stateService.updateState({
              ...this.state,
              car: `${data.mfrName} ${data.vehicleModelSeriesName} ${
                data.description
              } [${extractYear(data.beginYearMonth)}${
                data.endYearMonth ? '-' : ''
              }${extractYear(data.endYearMonth)}]`,
            });
          },
          error: (err) => {
            console.log('Error while getting tecdoc car details data', err);
          },
        });
    });
  }

  ngOnDestroy(): void {
    this.stateService.updateState({
      ...this.state,
      isModel: false,
      modelId: undefined,
      vehicleType: undefined,
      car: undefined,
    });
    this.subscriptions.unsubscribe();
  }
}
