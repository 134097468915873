<div class="flex flex-col bg-white  border rounded-md border-gray-300 shadow-sm h-auto w-full p-5">
    <div class="flex flex-row">
        <div class="flex flex-col w-1/3 justify-start items-center p-3">
            <img [src]="selectedImage ? selectedImage : '../../../../assets/placeholder.png'"
                class="h-60 object-contain min-w-20">
            <div class="flex flex-row justify-center items-center flex-wrap m-2 gap-2">
                @for (img of images; track $index) {
                <img [src]="img.imageURL200"
                    class="min-w-10 w-24 object-contain border p-1 aspect-square cursor-pointer"
                    (click)="selectImage($index)">
                }
            </div>

        </div>
        <div class="flex w-2/3 flex-row">
            <div class="flex flex-col gap-3 p-2 w-2/3" *ngIf="item">
                @if(item.mfrName){
                <div>Zīmols: {{item.mfrName}}</div>
                }
                @if(item.articleNumber){
                <div>Artikula numurs: {{item.articleNumber}}</div>
                }
                @if(item.gtins && item.gtins[0]){
                <div>GTIN/EAN: {{item.gtins[0]}}</div>
                }
                @if(item.tradeNumbers && item.tradeNumbers.length){
                <div>Tirdzniecības numuri: <span class="cursor-pointer text-blue-500 hover:text-blue-700"
                        [routerLink]="'/shop/catalogue/search/' + tradeNumber"
                        *ngFor="let tradeNumber of item.tradeNumbers; index as i"
                        class="mr-1 text-nowrap">{{tradeNumber}}{{item.tradeNumbers[i + 1] ? ',&nbsp;' : ''}}</span>
                </div>
                }
                <div class="">Produktu grupa: {{item.genericArticles[0].genericArticleDescription}}</div>

                <!-- @if(item.misc && item.misc.articleStatusDescription){
                <div>Statuss: {{item.misc.articleStatusDescription}}</div>
                }
                @if(item.misc && item.misc.quantityPerPackage){
                <div>Iepakojuma vienība: {{item.misc.quantityPerPackage}}</div>
                } -->

                <div *ngIf="item.articleCriteria.length">
                    <mat-divider></mat-divider>
                    <div class="flex flex-col mt-2">
                        <div class="font-semibold" *ngIf="item.articleCriteria && item.articleCriteria.length">Tehniskie
                            Parametri</div>
                        @for(criteria of item.articleCriteria; track $index){
                        <div class="flex ml-3"><span class="mr-1 text-nowrap">{{criteria.criteriaAbbrDescription ||
                                criteria.criteriaDescription}}:</span>
                            <span class="mr-2 flex-nowrap">{{criteria.formattedValue + (criteria.criteriaUnitDescription
                                ? '
                                ' +
                                criteria.criteriaUnitDescription : '')}};</span>
                        </div>
                        }
                    </div>
                </div>

                <div *ngIf="oeNumbers.length">
                    <mat-divider></mat-divider>
                    <div class="mt-2">
                        <div class="flex justify-between cursor-pointer" (click)="isSectionExpanded = !isSectionExpanded"><span
                                class="font-semibold">OE numuri</span><mat-icon>{{
                                isSectionExpanded ? 'expand_less'
                                : 'expand_more' }}</mat-icon></div>
                        <div *ngIf="isSectionExpanded">
                            <div *ngFor="let brand of oeNumbers" class="mb-2 ml-3">
                                {{brand.mfrName}} <br>
                                <div class="flex flex-wrap gap-2 ml-3">
                                    <span class="ml-3 cursor-pointer text-blue-500  hover:text-blue-700"
                                        *ngFor="let number of brand.articleNumbers"
                                        [routerLink]="'/shop/catalogue/search/' + number">{{number}}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div *ngIf="brands && brands.length">
                    <mat-divider></mat-divider>
                    <div class="mt-2"><span class="font-semibold">Saderīgie transportlīdzekļi</span>
                        <div>
                            <span *ngFor="let brand of brands"
                                class="mb-2 ml-3 cursor-pointer whitespace-nowrap text-blue-500  hover:text-blue-700"
                                (click)="showLinkedModels(brand.manuId)"
                                [ngClass]="{'font-bold !text-appPrimary !hover:text-appPrimary': selectedBrand == brand.manuId}">{{brand.manuName}}</span>
                            <div *ngIf="isBrandSelected && modelsTableDataSource" class="mt-3 flex flex-col">
                                <table mat-table [dataSource]="modelsTableDataSource" matSort>

                                    <!-- Car Description Column -->
                                    <ng-container matColumnDef="carDesc">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Transportlīdzeklis </th>
                                        <td mat-cell *matCellDef="let element"
                                            class="text-blue-500  hover:text-blue-700 cursor-pointer"
                                            (click)="loadCarPage(element.carId)"> {{element.modelDesc}}&nbsp;{{element.carDesc}} </td>
                                    </ng-container>

                                    <!-- Year of Construction Column -->
                                    <ng-container matColumnDef="yearOfConstruction">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Izlaiduma gads </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{ getFormattedDate(element?.yearOfConstructionFrom) }} - {{
                                            getFormattedDate(element?.yearOfConstructionTo) }}
                                        </td>
                                    </ng-container>

                                    <!-- Power in kW Column -->
                                    <ng-container matColumnDef="powerKw">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> kW </th>
                                        <td mat-cell *matCellDef="let element"> {{element.powerKwFrom}} </td>
                                    </ng-container>

                                    <!-- Horsepower Column -->
                                    <ng-container matColumnDef="powerHp">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ZS </th>
                                        <td mat-cell *matCellDef="let element"> {{element.powerHpFrom}} </td>
                                    </ng-container>

                                    <!-- Cylinder Capacity Column -->
                                    <ng-container matColumnDef="cylinderCapacity">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> cm3 </th>
                                        <td mat-cell *matCellDef="let element"> {{element.cylinderCapacity}} </td>
                                    </ng-container>

                                    <!-- Construction Type Column -->
                                    <ng-container matColumnDef="constructionType">
                                        <th mat-header-cell *matHeaderCellDef> Dizains </th>
                                        <td mat-cell *matCellDef="let element"> {{element.constructionType}} </td>
                                    </ng-container>

                                    <!-- Row Definition -->
                                    <tr mat-header-row *matHeaderRowDef="modelsTableDisplayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: modelsTableDisplayedColumns;"></tr>
                                </table>
                                <button mat-raised-button (click)="loadMore()"
                                    *ngIf="isBrandSelected && showMoreModelsButton"
                                    class="w-fit mx-auto mt-2 !font-semibold !text-appPrimary">Vairāk</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="flex flex-col w-1/3 justify-start text-center items-center pr-6 mt-10" *ngIf="showPrice">
                <div class="flex items-center justify-center space-x-2 mb-4">
                    <input #quantityInput type="number" (click)="removeRedirect($event)"
                        class="w-12 text-center border border-gray-300 rounded" value="1" min="1" />
                </div>

                @if(getPrices() && (getPrices().aspl || getPrices().ape)){
                <div class="flex flex-col w-full">
                    <div *ngIf="getPrices().aspl" class="flex flex-col w-full mb-8">
                        <span class="font-bold">AS/PL</span>
                        <span class="font-semibold text-lg">{{getPrices().aspl * 1.21 | currency: 'EUR'}}</span>
                        <span>{{getPrices().aspl | currency: 'EUR'}} (bez PVN)</span>
                    </div>
                    <div *ngIf="getPrices().ape" class="flex flex-col w-full mb-8">
                        <span class="font-bold">APE</span>
                        <span class="font-semibold text-lg">{{getPrices().ape * 1.21 | currency: 'EUR'}}</span>
                        <span>{{getPrices().ape | currency: 'EUR'}} (bez PVN)</span>
                    </div>
                </div>
                } @else {
                <span class="text-sm font-normal mb-3">Cenas nav</span>
                }
                <div class="flex justify-center w-20 items-center text-green-700 font-bold rounded border-amber-950 hover:bg-neutral-200 shadow-md border-2 h-fit p-2"
                    (click)="addToCart(quantityInput.value, $event)">
                    Pirkt</div>
            </div>
        </div>
    </div>
</div>