import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationService } from '../notification/notification.service';

export interface BasketItem {
  productId: string;
  name: string;
  price: number;
  quantity: number;
  imageUrl: string;
  car?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  private basketSubject = new BehaviorSubject<BasketItem[]>([]);
  private basketItems: BasketItem[] = [];
  private title = new BehaviorSubject<string>('Grozs');

  constructor(private notificationService: NotificationService) {
    this.loadBasket();
  }

  getBasket(): Observable<BasketItem[]> {
    return this.basketSubject.asObservable();
  }

  getItems(): BasketItem[] {
    return this.basketItems;
  }

  addItem(item: BasketItem): void {
    const existingItem = this.basketItems.find(
      (basketItem) => basketItem.productId === item.productId
    );
    if (existingItem) {
      existingItem.quantity += item.quantity;
    } else {
      this.basketItems.push(item);
    }
    this.updateBasket();
    this.notificationService.showNotification('Prece pievienota grozam!');
  }

  setTitle(title: string) {
    this.title.next(title);
    console.log(this.title);
  }

  getTitle(): Observable<string> {
    return this.title.asObservable();
  }

  removeItem(productId: string): void {
    this.basketItems = this.basketItems.filter(
      (item) => item.productId !== productId
    );
    this.updateBasket();
  }

  updateItemQuantity(productId: string, quantity: number): void {
    const item = this.basketItems.find(
      (basketItem) => basketItem.productId === productId
    );
    if (item) {
      item.quantity = quantity;
      this.updateBasket();
    }
  }

  clearBasket(): void {
    this.basketItems = [];
    this.updateBasket();
  }

  getTotal(): number {
    return this.basketItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  }

  private updateBasket(): void {
    this.basketSubject.next([...this.basketItems]);
    this.saveBasket();
  }

  private loadBasket(): void {
    const savedBasket = localStorage.getItem('basket');
    if (savedBasket) {
      this.basketItems = JSON.parse(savedBasket);
      this.basketSubject.next(this.basketItems);
    }
  }

  private saveBasket(): void {
    localStorage.setItem('basket', JSON.stringify(this.basketItems));
  }
}
