<header class="flex flex-col justify-between items-center px-10 bg-neutral-50 pb-4 pt-2 border-b-2 border-appPrimary w-full shadow-sm">
  <div class="flex items-center justify-between w-full">
    <div class="w-1/4 flex justify-start items-center">
      <a routerLink="shop" class="ml-10 mr-24">
        <img src="assets/logo.svg" alt="Logo" class="logo h-16">
      </a>
      <div class="flex flex-col text-gray-600 text-sm">
        <span><a href="mailto:info@arsiko.com">info&#64;arsiko.com</a></span>
        <span><a href="tel:+37122462117">+371 22462117</a></span>
      </div>
    </div>
    <nav class="w-1/2 flex justify-evenly items-center">
      <a routerLink="shop" class="text-appPrimary font-semibold px-3"
        routerLinkActive="font-bold underline decoration-2 underline-offset-4"
        [routerLinkActiveOptions]="{exact: true}">Veikals</a>
      <a routerLink="/delivery" class="text-appPrimary font-semibold px-3"
        routerLinkActive="font-bold underline decoration-2 underline-offset-4">Piegāde un apmaksa</a>
      <a routerLink="/return-policy" class="text-appPrimary font-semibold px-3"
        routerLinkActive="font-bold underline decoration-2 underline-offset-4">Atgriešana</a>
      <a routerLink="/about-us" class="text-appPrimary font-semibold px-3"
        routerLinkActive="font-bold underline decoration-2 underline-offset-4">Par
        mums</a>
      <a routerLink="/contacts" class="text-appPrimary font-semibold px-3"
        routerLinkActive="font-bold underline decoration-2 underline-offset-4">Kontakti</a>
    </nav>
    <div class="w-1/4 flex justify-end items-center pr-8">
      <div>
        @if(authService.isLoggedIn()){
        <a routerLink="/profile" class="text-gray-700 hover:text-gray-500 mr-16"><i class="fa-solid fa-user text-xl"></i></a>
        <span (click)="logout()" class="cursor-pointer text-neutral-700 hover:text-gray-500 ml-4">Iziet</span>
        }
        @if(!authService.isLoggedIn()){
        <span routerLink="/login" class="cursor-pointer text-neutral-700 hover:text-gray-500">Ienākt</span>
        }

        <a routerLink="/checkout/cart" class="text-gray-700 hover:text-gray-500 ml-16"><i
            class="fa-solid fa-cart-shopping text-xl"></i></a>
      </div>
    </div>
  </div>
  <div class="w-full flex justify-center">
    <input type="text" placeholder="Meklēt pēc OE numura vai nosaukuma..." [(ngModel)]="searchInput"
      (keydown.enter)="onSearch()">
    <button class="search-button" (click)="onSearch()">
      <i class="fa fa-search text-neutral-700 hover:text-neutral-500"></i>
    </button>

  </div>
</header>