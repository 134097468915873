import { Component, OnDestroy, OnInit } from '@angular/core';
import { CategoryGridComponent } from '../category-grid/category-grid.component';
import { TecdocService } from '../../../services/tecdoc/tecdoc.service';
import { CategoryNode } from '../../home/home.component';
import { ActivatedRoute } from '@angular/router';
import { StateService } from '../../../services/state/state.service';
import { extractYear } from '../../../shared';

@Component({
  selector: 'app-model-categories',
  standalone: true,
  imports: [CategoryGridComponent],
  templateUrl: './model-categories.component.html',
  styleUrl: './model-categories.component.scss',
})
export class ModelCategoriesComponent implements OnInit, OnDestroy {
  categoryTree: CategoryNode[] = [];
  state: any;
  id: any;

  constructor(
    private tecdocService: TecdocService,
    private activatedRoute: ActivatedRoute,
    private stateService: StateService
  ) {}

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('modelId');
    this.stateService.currentState.subscribe((state) => {
      this.state = state;
    });
    this.stateService.updateState({ ...this.state, isModelPage: true });
    this.tecdocService
      .getModelCategories({ modelId: this.id, type: this.state.vehicleType })
      .subscribe({
        next: (response) => {
          this.categoryTree = response;
        },
        error: (err) => {
          console.log('Error while getting tecdoc model data', err);
        },
      });
  }

  ngOnDestroy(): void {
    this.stateService.updateState({ ...this.state, isModelPage: false });
  }
}
