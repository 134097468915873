import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CATEGORIES } from '../../constants';
import { ItemService } from '../../services/item/item.service';
import { Category } from '../../models/category.model';
import { SideNavItemComponent } from './side-nav-item/side-nav-item.component';
import { TecdocService } from '../../services/tecdoc/tecdoc.service';
import { State, StateService } from '../../services/state/state.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-side-nav',
  standalone: true,
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss',
  animations: [
    trigger('slideDown', [
      state(
        'closed',
        style({
          height: '0',
          overflow: 'hidden',
          opacity: '0',
        })
      ),
      state(
        'open',
        style({
          height: '*',
          opacity: '1',
        })
      ),
      transition('closed <=> open', animate('300ms ease-in-out')),
    ]),
  ],
  imports: [RouterModule, SideNavItemComponent, CommonModule],
})
export class SideNavComponent {
  categories: Category[] = [];
  state!: State;
  isModel: boolean = false;
  modelId?: number;

  constructor(
    private tecdocService: TecdocService,
    private stateService: StateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    setTimeout(
      () =>
        this.stateService.currentState.subscribe((state) => {
          this.state = state;
          if (state.isModel != this.isModel || state.modelId != this.modelId) {
            this.isModel = state.isModel;
            this.modelId = state.modelId;
            // setTimeout(() => this.loadCategories(), 1000);
            this.loadCategories();
          } else if (!state.isModel && this.categories.length == 0) {
            this.loadCategories();
          }
        }),
      100
    );
  }

  toggleCategory(index: number): void {
    const category = this.categories[index];
    if (category.children?.length) {
      category.open = !category.open;
    }
  }

  loadCategories() {
    if (this.isModel && this.modelId) {
      this.tecdocService
        .getModelCategories({
          modelId: this.modelId,
          type: this.state.vehicleType,
        })
        .subscribe({
          next: (response) => {
            this.categories = response;
            this.cdr.detectChanges();
          },
          error: (err) => {
            console.log('Error while getting tecdoc model categories', err);
          },
        });
    } else {
      this.tecdocService.getCategories().subscribe({
        next: (response) => {
          this.categories = response;
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log('Error while getting tecdoc data', err);
        },
      });
    }
  }
}
