import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, of } from 'rxjs';
import { OrderService } from '../../../../services/order/order.service';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-payment-accept',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './payment-accept.component.html',
  styleUrl: './payment-accept.component.scss',
})
export class PaymentAcceptComponent {
  orderId: string | null = null;
  orderDetails: any = null;
  paymentSuccess = false;
  errorMessage = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    // Get the order ID from the URL parameters
    this.orderId = this.route.snapshot.queryParamMap.get('orderId');

    if (this.orderId) {
      // Fetch the order details from the backend using the order ID
      this.fetchOrderDetails(this.orderId);
    } else {
      // No order ID, show error
      this.errorMessage = 'No order ID provided.';
    }
  }

  fetchOrderDetails(orderId: string) {
    this.orderService
      .getOrderById(orderId)
      .pipe(
        catchError((err) => {
          console.error('Error fetching order details:', err);
          this.errorMessage = 'Error fetching order details.';
          return of(null); // Handle error without breaking the app
        })
      )
      .subscribe((order) => {
        if (order && order.status === 'paid') {
          this.orderDetails = order;
          this.paymentSuccess = true;
        } else {
          this.errorMessage = 'Payment was not successful or order not found.';
        }
      });
  }

  viewOrderDetails() {
    this.router.navigate(['/order-details', { orderId: this.orderId }]);
  }

  printReceipt() {
    window.print();
  }

  goToCheckout() {
    this.router.navigate(['/checkout/order']);
  }
}
