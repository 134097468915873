<div class="payment-method-container shadow-md bg-neutral-50 rounded-md ml-3 mt-3 mb-3 border">
    <h2 class="text-2xl">Apmaksas veids</h2>

    <!-- Payment Options -->
    <div class="form-group" [formGroup]="paymentForm">
        <mat-radio-group formControlName="paymentMethod" class="flex flex-col">
            <mat-radio-button *ngFor="let option of paymentOptions" [value]="option.value"
                (change)="onPaymentMethodChange(option.value)" [disabled]="option.value == 'prepayment'">
                {{ option.label }}
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <!-- Card Payment Fields -->
    <div *ngIf="selectedPaymentMethod === 'card'" [formGroup]="cardDetails">
        <div class="w-full flex justify-center my-3">
            <img src="https://bank.paysera.com/assets/image/payment_types/card.png" alt="Visa, MasterCard, Maestro" />
        </div>
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Kartes numurs</mat-label>
            <input matInput formControlName="cardNumber" placeholder="1234 5678 9012 3456" />
            <mat-error
                *ngIf="paymentForm.get('cardDetails.cardNumber')?.invalid && paymentForm.get('cardDetails.cardNumber')?.touched">
                Please enter a valid card number
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="half-width">
            <mat-label>Derīguma termiņš (MM/GG)</mat-label>
            <input matInput formControlName="expiryDate" placeholder="MM/YY" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="half-width">
            <mat-label>CVV / CVC</mat-label>
            <input matInput formControlName="cvv" placeholder="123" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Kartes lietotāja vārds</mat-label>
            <input matInput formControlName="cardHolder" placeholder="Name on Card" />
        </mat-form-field>
    </div>
    <!-- Bank Payment Fields -->
    <div *ngIf="selectedPaymentMethod === 'bank'" [formGroup]="bankDetails">
        <div class="w-full flex justify-center my-3 gap-2">
            <img src="https://bank.paysera.com/assets/image/payment_types/hanzalv.png" alt="AS Swedbank bank" />
            <img src="https://bank.paysera.com/assets/image/payment_types/seblv.png" alt="AB SEB bank" />
            <img class="mr-2" src="https://bank.paysera.com/assets/image/payment_types/lv_dnb.png" alt="Luminor" />
            <img src="https://bank.paysera.com/assets/image/payment_types/parexlv.png" alt="Citadele bank" />
        </div>

        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Izvēlieties banku</mat-label>
            <mat-select formControlName="bank">
                <mat-option value="hanzalv">Swedbank</mat-option>
                <mat-option value="seblv">SEB</mat-option>
                <mat-option value="parexlv">Citadele</mat-option>
                <mat-option value="lv_dnb">Luminor</mat-option>
            </mat-select>
            <mat-error
                *ngIf="paymentForm.get('bankDetails.bank')?.invalid && paymentForm.get('bankDetails.bank')?.touched">
                Please select a bank
            </mat-error>
        </mat-form-field>
    </div>
    <div class="flex justify-end">
        <button mat-raised-button color="primary" type="submit" [disabled]="!isFormValid()"
            (click)="submit()">Apstiprināt maksājumu</button>
    </div>
</div>