<div class="delivery-methods shadow-md bg-white rounded-md ml-3 mt-3">
    <h3 class="text-2xl mb-1.5">Piegādes veidi</h3>

    <mat-radio-group class="delivery-options" [(ngModel)]="selectedMethod">
        <div *ngFor="let method of deliveryMethods" class="delivery-option-container">
            <div class="delivery-option" (click)="onSelectDeliveryMethod(method)">
                <mat-radio-button [value]="method" class="radio-btn"></mat-radio-button>

                <div class="option-content">
                    <div class="flex">
                        <div class="flex-grow">
                            <div class="method-name"></div>
                            <strong>{{ method.name }}</strong>
                            <div class="method-description">{{ method.description }}</div>
                            <div class="method-info">
                                <p>Piegādes datums: {{ method.estimatedDeliveryDate }}</p>
                            </div>
                        </div>
                        <div>
                            <p class="method-price mt-3">{{ method.price | currency: 'EUR' }}</p>
                        </div>
                    </div>

                    <!-- Display the dropdown if there are pickup points -->
                    <div *ngIf="method.pickupPoints && selectedMethod?.id === method.id" class="pickup-dropdown">
                        <mat-form-field class="full-width">
                            <mat-label>Select Pickup Point</mat-label>
                            <mat-select [(ngModel)]="selectedPickupPoint"
                                (selectionChange)="onPickupPointChange($event.value)">
                                <mat-option *ngFor="let point of method.pickupPoints" [value]="point.id">
                                    {{ point.address.city | titlecase }}: {{ point.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <a href="#">Pickup punktu saraksts (karte)</a>
                    </div>
                </div>
            </div>
        </div>
    </mat-radio-group>
    <div class="flex justify-end">
        <button mat-raised-button color="primary" type="button" (click)="saveDeliveryOption()"
            [disabled]="isButtonDisabled()">Saglabāt</button>
    </div>
</div>