<div class="flex justify-center items-center">
    <mat-card class="flex flex-col mt-36 w-1/4">
      <mat-card-title class="w-100 text-start pl-5 py-2 title text-amber-950">Reģistrācija</mat-card-title>
      <mat-card-content>
        <form (ngSubmit)="onSubmit()" #registrationForm="ngForm" class="flex flex-col">
          <!-- Email -->
          <mat-form-field appearance="fill">
            <mat-label>E-pasts</mat-label>
            <input
              matInput
              [(ngModel)]="model.email"
              name="email"
              required
              email
              #emailInput="ngModel"
            >
            <mat-error *ngIf="emailInput.invalid && emailInput.touched">Derīgs e-pasts ir obligāts</mat-error>
          </mat-form-field>
  
          <!-- Password -->
          <mat-form-field appearance="fill">
            <mat-label>Parole</mat-label>
            <input
              matInput
              [(ngModel)]="model.password"
              name="password"
              type="password"
              required
              minlength="6"
              #passwordInput="ngModel"
            >
            <mat-error *ngIf="passwordInput.invalid && passwordInput.touched">Parolei jābūt vismaz 6 rakstzīmēm</mat-error>
          </mat-form-field>
  
          <!-- Password Confirmation -->
          <mat-form-field appearance="fill">
            <mat-label>Atkārtojiet paroli</mat-label>
            <input
              matInput
              [(ngModel)]="model.passwordRepeat"
              name="passwordRepeat"
              type="password"
              required
              [ngClass]="{'error': passwordMismatch}"
              #passwordRepeatInput="ngModel"
            >
            <mat-error *ngIf="passwordRepeatInput.invalid && passwordRepeatInput.touched">Lūdzu, atkārtojiet paroli</mat-error>
            <mat-error *ngIf="passwordMismatch">Paroles nesakrīt</mat-error>
          </mat-form-field>
  
          <!-- Submit Button -->
          <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="registrationForm.invalid || passwordMismatch"
          >
            Reģistrēties
          </button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  