import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { CarSearchComponent } from "./car-search/car-search.component";

@Component({
  selector: 'app-shop',
  standalone: true,
  imports: [RouterOutlet, SideNavComponent, CarSearchComponent],
  templateUrl: './shop.component.html',
  styleUrl: './shop.component.scss',
})
export class ShopComponent {}
