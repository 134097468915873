<div class="p-2 bg-neutral-50 flex justify-evenly items-start pt-10 border-t">
    <div class="w-1/6 flex flex-col justify-start items-center">
        <span class="font-bold text-amber-950">Informācija</span>
        <div class="text-sm text-center text-wrap flex flex-col">
            <a routerLink="/about-us" class="text-gray-800 hover:text-gray-600 px-3 cursor-pointer">Par mums</a>
            <a routerLink="/contacts" class="text-gray-800 hover:text-gray-600 px-3 cursor-pointer">Kontakti</a>
        </div>
    </div>
    <div class="w-1/6 flex flex-col justify-start items-center">
        <span class="font-bold text-amber-950">Klientu apkalpošana</span>
        <div class="text-sm text-center text-wrap flex flex-col">
            <a routerLink="/" class="text-gray-800 hover:text-gray-600 px-3 cursor-pointer">Apmaksas veidi</a>
            <a routerLink="/return-policy" class="text-gray-800 hover:text-gray-600 px-3 cursor-pointer">Preces
                atgriešana</a>
            <a routerLink="/" class="text-gray-800 hover:text-gray-600 px-3 cursor-pointer">Pasūtījuma izsekošna</a>
        </div>
    </div>
    <div class="w-1/6 flex flex-col justify-start items-center">
        <span class="font-bold text-amber-950">Kontakti</span>
        <div class="text-sm text-center text-wrap">
            <p><a href="mailto:info@arsiko.com">info&#64;arsiko.com</a></p>
            <p><a href="tel:+37122462117">+371 22462117</a></p>
            <p>Plakanciema Egles 18, <br> Vaivadi, LV-2127</p>
        </div>
    </div>
    <div class="w-1/6 flex flex-col justify-start items-center">
        <span class="font-bold text-amber-950">Cita Informācija</span>
    </div>
</div>
<div class="flex">
    <div class="images flex w-1/2 justify-center items-center h-6 gap-5 mb-2 p-1">
        <img src="https://bank.paysera.com/assets/image/payment_types/hanzalv.png" alt="AS Swedbank bank" />
        <img src="https://bank.paysera.com/assets/image/payment_types/seblv.png" alt="AB SEB bank" />
        <img src="https://bank.paysera.com/assets/image/payment_types/lv_dnb.png" alt="Luminor" />
        <img src="https://bank.paysera.com/assets/image/payment_types/parexlv.png" alt="Citadele bank" />
        <img src="https://bank.paysera.com/assets/image/payment_types/card.png" alt="Visa, MasterCard, Maestro" />
    </div>
</div>