<div class="w-full h-full py-4 px-3 bg-neutral-50">
    <div class="w-full flex justify-end items-center">
        <div class="flex items-center gap-2 mb-1 mr-1" *ngIf="showItems && items.length">
            <div (click)="previousPage()" *ngIf="page > 1" class="hover:text-neutral-600 cursor-pointer">Iepriekšējā
            </div>
            <div *ngIf="maxPages">{{this.page}}</div>
            <div (click)="nextPage()" *ngIf="maxPages && page < maxPages" class="hover:text-neutral-600 cursor-pointer">
                Nākamā</div>
            <div *ngIf="maxPages">{{'('+ this.maxPages +')'}}</div>
        </div>
    </div>
    <div class="flex flex-col mt-2 w-full">
        @if(showCategories && !showItems && category && category.children && category.children.length){
        <app-category-grid [categories]="category.children" [isSub]="true"
            [isDetails]="showCategories"></app-category-grid>
        } @else {
        <div class="flex flex-col justify-start gap-2 items-center w-full mb-6">
            @for(item of items; track $index){@if(getPrices(item) && (getPrices(item).aspl || getPrices(item).ape)){
            <div [routerLink]="['../../item/' + item.dataSupplierId + '/' + item.articleNumber]"
                class="flex flex-row justify-start items-center text-center text-lg shadow-md bg-white p-3 cursor-pointer w-full border border-gray-300 rounded-md">
                <div class="min-w-28 mr-4">
                    <img [src]="item.images && item.images.length ? item.images[0].imageURL100 : '../../../../assets/placeholder.png'"
                        class="h-20 w-full object-contain"
                        [routerLink]="['../../item/' + item.dataSupplierId + '/' + item.articleNumber]">
                </div>

                <div class="flex flex-col justify-evenly items-start flex-grow"
                    [routerLink]="['../../item/' + item.dataSupplierId + '/' + item.articleNumber]">
                    <span class="font-bold text-lg mb-2">{{item.mfrName}} - <span
                            class="font-normal">{{item.articleNumber}}</span></span>
                    <div class="flex flex-wrap text-sm text-left">
                        @for(criteria of item.articleCriteria; track $index){
                        <div *ngIf="criteria.immediateDisplay" class="w-full justify-start">
                            <span class="font-bold mr-1">{{criteria.criteriaAbbrDescription ||
                                criteria.criteriaDescription}}:</span>
                            <span class="mr-2">{{criteria.formattedValue + (criteria.criteriaUnitDescription ? ' ' +
                                criteria.criteriaUnitDescription : '')}};</span>
                        </div>
                        }
                        @if(item.tradeNumbers && item.tradeNumbers.length){
                        <div class="basis-full"></div>
                        <span class="font-bold mr-1">Tirdzniecības numuri:</span>
                        @for(number of item.tradeNumbers; track $index){
                        <span>{{number}}{{item.tradeNumbers[$index + 1] ? ',&nbsp;' : ''}}</span>
                        }
                        }

                    </div>
                </div>
                <div class="min-w-40 flex flex-col mr-16"
                    [routerLink]="['../../item/' + item.dataSupplierId + '/' + item.articleNumber]">
                    @if(getPrices(item) && (getPrices(item).aspl || getPrices(item).ape)){
                    <div *ngIf="getPrices(item).aspl" class="flex flex-col w-full mb-8">
                        <span class="font-semibold">AS/PL</span>
                        <span class=" text-lg">{{getPrices(item).aspl * 1.21 | currency: 'EUR'}}</span>
                        <span>{{getPrices(item).aspl | currency: 'EUR'}} (bez PVN)</span>
                    </div>
                    <div *ngIf="getPrices(item).ape" class="flex flex-col w-full mb-8">
                        <span class="font-semibold">APE</span>
                        <span class="text-lg">{{getPrices(item).ape * 1.21 | currency: 'EUR'}}</span>
                        <span>{{getPrices(item).ape | currency: 'EUR'}} (bez PVN)</span>
                    </div>
                    } @else {
                    <span class="text-sm font-normal">Cenas nav</span>
                    }
                </div>
                <div class="flex flex-col mr-3">
                    <div class="flex items-center justify-center space-x-2 mb-4">
                        <input #quantityInput type="number" (click)="removeRedirect($event)"
                            class="w-12 text-center border border-gray-300 rounded" value="1" min="1" />
                    </div>
                    <div class="flex justify-center w-20 items-center text-green-700 font-bold rounded border-amber-950 hover:bg-neutral-200 shadow-md border-2 h-fit p-2"
                        (click)="addToCart(item, quantityInput.value, $event)">
                        Pirkt</div>
                </div>


            </div>
            }}
        </div>
        <div class="flex w-full justify-end items-center gap-2">
            <div (click)="previousPage()" *ngIf="page > 1" class="hover:text-neutral-600 cursor-pointer">Iepriekšējā
            </div>
            <div *ngIf="maxPages">{{this.page}}</div>
            <div (click)="nextPage()" *ngIf="maxPages && page < maxPages" class="hover:text-neutral-600 cursor-pointer">
                Nākamā</div>
            <div *ngIf="maxPages">{{'('+ this.maxPages +')'}}</div>
        </div>
        }
    </div>
</div>