import { Component, OnInit } from '@angular/core';
import { SideNavComponent } from '../../side-nav/side-nav.component';
import { Router, RouterModule } from '@angular/router';
import { StateService } from '../../../services/state/state.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-catalogue',
  standalone: true,
  imports: [SideNavComponent, RouterModule, CommonModule],
  templateUrl: './catalogue.component.html',
  styleUrl: './catalogue.component.scss',
})
export class CatalogueComponent implements OnInit {
  state: any;
  constructor(private stateService: StateService, private router: Router) {}
  ngOnInit(): void {
    this.stateService.currentState.subscribe((state) => {
      this.state = state;
    });
  }

  clear() {
    this.router.navigate(['/shop']);
  }
}
