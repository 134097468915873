import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-top-menu',
  standalone: true,
  imports: [RouterModule, FormsModule],
  templateUrl: './top-menu.component.html',
  styleUrl: './top-menu.component.scss',
})
export class TopMenuComponent {
  searchInput!: string;
  constructor(public authService: AuthService, private router: Router) {}

  logout() {
    this.authService.logout();
  }

  onSearch() {
    if (this.searchInput.length == 0) return;
    console.log(`Searching for ${this.searchInput}`);
    this.router.navigate([`/shop/catalogue/search/${this.searchInput}`]);
  }
}
