import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DpdService {
  private baseUrl = `${environment.apiBaseUrl}/dpd`;

  constructor(private http: HttpClient) {}

  createShipment(shipmentData: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/create-shipment`, shipmentData);
  }

  generateLabel(shipmentIds: string[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/generate-label`, { shipmentIds });
  }

  requestCourier(courierData: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/request-courier`, courierData);
  }

  getLockers(): Observable<any> {
    return this.http.get(`${this.baseUrl}/lockers`);
  }
}
