import { Component } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  ReactiveFormsModule,
  FormGroup,
  FormControl,
  FormsModule,
} from '@angular/forms';
import { getClosestDate } from '../../../../shared';
import { StateService } from '../../../../services/state/state.service';
import { DpdService } from '../../../../services/dpd/dpd.service';

export interface DeliveryMethod {
  id: number;
  name: string;
  description: string;
  price: number;
  pickupPoints?: any[]; // Optional: for options with a dropdown of delivery points
  selectedPoint?: string; // Selected pickup point if dropdown is present
  estimatedDeliveryDate: string;
}

@Component({
  selector: 'app-delivery-methods',
  standalone: true,
  imports: [
    CommonModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    TitleCasePipe,
  ],
  templateUrl: './delivery-methods.component.html',
  styleUrls: ['./delivery-methods.component.scss'],
})
export class DeliveryMethodsComponent {
  deliveryMethods: DeliveryMethod[] = [
    {
      id: 1,
      name: 'ARSIKO',
      description: 'Paņemšu pats ARSIKO birojā',
      price: 0,
      estimatedDeliveryDate: getClosestDate(),
    },
    {
      id: 2,
      name: 'DPD',
      description: 'DPD Pickup punkti',
      price: 1.99,
      pickupPoints: [
        'Rīgas Kleistu ielas Mego pakomāts',
        'Alfas pakomāts',
        'Lido pakomāts',
      ],
      estimatedDeliveryDate:
        'Piegādes datums tiks precizēts pēc pasūtījuma apstrādes',
    },
    {
      id: 3,
      name: 'DPD',
      description: 'DPD kurjers',
      price: 5,
      estimatedDeliveryDate:
        'Piegādes datums tiks precizēts pēc pasūtījuma apstrādes',
    },
  ];

  selectedMethod: DeliveryMethod | null = null;
  selectedPickupPoint: string = '';
  dpdLockers: any[] = [];

  constructor(
    private stateService: StateService,
    private dpdService: DpdService
  ) {
    this.selectedMethod = this.deliveryMethods[0];
    this.saveDeliveryOption();
  }

  ngOnInit(): void {
    this.dpdService.getLockers().subscribe({
      next: (data) => {
        console.log(data);
        this.dpdLockers = data;
        this.dpdLockers.sort((a: any, b: any) =>
          (a.address.city as string).localeCompare(b.address.city)
        );
        this.deliveryMethods[1].pickupPoints = this.dpdLockers;
      },
    });
  }

  // Method to select the delivery method
  onSelectDeliveryMethod(method: DeliveryMethod) {
    this.selectedMethod = method;
    this.selectedPickupPoint = method.selectedPoint || '';
  }

  // Method to set the selected pickup point for methods with pickup options
  onPickupPointChange(point: string) {
    if (this.selectedMethod && this.selectedMethod.pickupPoints) {
      this.selectedMethod.selectedPoint = point;
    }
  }

  saveDeliveryOption() {
    if (this.selectedMethod) {
      const deliveryInfo = {
        methodId: this.selectedMethod.id,
        methodName: this.selectedMethod.description,
        price: this.selectedMethod.price,
        selectedPoint: this.selectedPickupPoint || null,
        estimatedDeliveryDate: this.selectedMethod.estimatedDeliveryDate,
      };

      // Save to the state
      this.stateService.updateState({ selectedDeliveryMethod: deliveryInfo });

      console.log('Delivery option saved:', deliveryInfo);
    }
  }

  isButtonDisabled(): boolean {
    if (!this.selectedMethod) return true;
    else if (
      this.selectedMethod &&
      this.selectedMethod.pickupPoints?.length &&
      !this.selectedPickupPoint
    )
      return true;
    return false;
  }
}
