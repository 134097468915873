import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    CommonModule,
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
})
export class RegisterComponent {
  model: any = {
    email: '',
    password: '',
    passwordRepeat: '',
  };

  passwordMismatch: boolean = false;

  constructor(private authService: AuthService, private router: Router) {}

  onSubmit() {
    this.passwordMismatch = this.model.password !== this.model.passwordRepeat;
    if (!this.passwordMismatch) {
      this.authService
        .register(this.model.email, this.model.password)
        .subscribe({
          next: (res) => {
            if (res) {
              this.router.navigate(['shop/profile']);
              console.log('Register successful');
            } else {
              console.log('Register failed');
            }
          },
          error: (error) => {
            console.error('Register failed', error);
          },
        });
    }
  }
}
