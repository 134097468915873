<div class="checkout-form shadow-md bg-white rounded-md ml-3">
    <!-- Show form if in editing mode or if customer info is empty -->
    <form *ngIf="isEditing" [formGroup]="checkoutForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Vārds</mat-label>
                <input matInput formControlName="firstName" placeholder="Enter your first name" />
                <mat-error *ngIf="checkoutForm.get('firstName')?.invalid && checkoutForm.get('firstName')?.touched">
                    Vārds is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Uzvārds</mat-label>
                <input matInput formControlName="lastName" placeholder="Enter your last name" />
                <mat-error *ngIf="checkoutForm.get('lastName')?.invalid && checkoutForm.get('lastName')?.touched">
                    Uzvārds is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Telefona numurs</mat-label>
                <input matInput formControlName="phone" placeholder="Enter phone number" />
                <mat-error *ngIf="checkoutForm.get('phone')?.invalid && checkoutForm.get('phone')?.touched">
                    Enter a valid phone number
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Iela un mājas numurs</mat-label>
                <input matInput formControlName="street" placeholder="Street and house number" />
                <mat-error *ngIf="checkoutForm.get('street')?.invalid && checkoutForm.get('street')?.touched">
                    Street is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="half-width">
                <mat-label>Pasta indekss</mat-label>
                <input matInput formControlName="postalCode" placeholder="Postal code" />
                <mat-error *ngIf="checkoutForm.get('postalCode')?.invalid && checkoutForm.get('postalCode')?.touched">
                    Enter a valid postal code
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="half-width">
                <mat-label>Pilsēta</mat-label>
                <input matInput formControlName="city" placeholder="City" />
                <mat-error *ngIf="checkoutForm.get('city')?.invalid && checkoutForm.get('city')?.touched">
                    City is required
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-actions">
            <button mat-raised-button color="primary" type="submit" [disabled]="checkoutForm.invalid">Saglabāt</button>
        </div>
    </form>

    <!-- Show summary if not editing -->
    <div *ngIf="!isEditing" class="w-full">
        <h2 class="text-2xl">Piegādes un norēķinu informācija</h2>
        <div class="summary-section flex justify-between items-center p-4">
            <div *ngIf="customerInfo && !isCustomerInfoEmpty(customerInfo)">
                <p><strong>{{ customerInfo.firstName }} {{ customerInfo.lastName }}</strong></p>
                <p>{{ customerInfo.street }}, {{ customerInfo.city }}, {{ customerInfo.postalCode }}</p>
                <p>{{ customerInfo.phone }}</p>
            </div>
            <div *ngIf="customerInfo && isCustomerInfoEmpty(customerInfo)">Informācijas nav</div>
            <button mat-icon-button color="primary" (click)="editForm()">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
    </div>
</div>