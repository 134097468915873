<div class="w-full flex flex-col bg-neutral-50">
    <div class="text-2xl text-amber-950 font-bold mb-2 ml-8 mt-5 w-full" *ngIf="state.heading">{{state.heading}}
    </div>
    <div class="text-lg font-semibold text-gray-700 mb-2 ml-8 w-full" *ngIf="state.car"
        [ngClass]="{'mt-5': !state.heading}">
        <span [ngClass]="{'hover:text-gray-500 cursor-pointer': !state.isModelPage}"
            [routerLink]="'/shop/catalogue/model/V/' + state.modelId">{{state.car}}</span>
        <button class="search-button !bg-red-400" (click)="clear()">
            <i class="fa fa-times"></i>
        </button>
    </div>
    <div class="grid grid-cols-6 w-auto px-2 mt-1">
        <app-side-nav class="col-start-1 col-end-2 lg:col-end-1" *ngIf="!state.isItem"></app-side-nav>
        <div class="col-span-5 col-start-2"
            [ngClass]="{'col-span-6 col-start-1': state.isItem,'col-span-5 col-start-3 lg:col-start-2' : !state.isItem}">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>