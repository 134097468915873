import { Component, ElementRef, ViewChild } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';

@Component({
  selector: 'app-contacts',
  standalone: true,
  imports: [],
  templateUrl: './contacts.component.html',
  styleUrl: './contacts.component.scss',
})
export class ContactsComponent {
  @ViewChild('map')
  mapElement!: ElementRef;

  mapOptions: google.maps.MapOptions = {
    center: { lat: 56.72274583832689, lng: 24.063451374008793 },
    zoom: 9,
  };

  loader = new Loader({
    apiKey: 'AIzaSyDzrJagMFBbEi4552JRcpzMrBboESGLUFM',
    version: 'weekly',
  });

  ngOnInit(): void {
    this.loader
      .load()
      .then((google) => {
        var map = new google.maps.Map(
          this.mapElement.nativeElement,
          this.mapOptions
        );
        var marker = new google.maps.Marker({
          position: { lat: 56.72274583832689, lng: 24.063451374008793 },
          title: 'ARSIKO, Plakanciema Egles 18, Vaivadi',
        });
        marker.setMap(map);
      })
      .catch((e) => {
        console.debug('Map issue:' + e);
      });
  }
}
