<div class="flex flex-wrap gap-8 px-3 justify-center">
    @for(category of categoryTree; track $index){
    <div
        class="flex flex-col min-w-60 w-1/5 h-60 justify-start text-lg hover:shadow-lg border-gray-300 border bg-white shadow-md rounded-xl p-3">
        <!-- <img [src]="category.imageUrl ? category.imageUrl : '../../../../assets/placeholder.png'" class=""> -->
        <span [routerLink]="[getLink(category)]"
            class="cursor-pointer hover:text-neutral-700 h-1/5 overflow-hidden whitespace-nowrap">{{category.assemblyGroupName}}</span>
        <div *ngIf="category.children?.length" class="text-sm mt-2 flex-grow flex flex-col gap-1.5 overflow-hidden">
            <div *ngFor="let child of category.children?.slice(0, 4)" [routerLink]="[getLink(child)]"
                class="cursor-pointer hover:text-neutral-700">
                {{child.assemblyGroupName}}</div>

        </div>
        <div *ngIf="category.children && category.children.length > 5" [routerLink]="[getLink(category)+'/details']"
            class="mt-2 cursor-pointer border rounded-lg w-fit p-2 text-sm border-appPrimary hover:shadow-md shadow-appPrimary">
            Rādīt visu
        </div>
    </div>
    }
</div>