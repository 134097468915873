import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomerInfo, StateService } from '../../../../services/state/state.service';

@Component({
  selector: 'app-checkout-form',
  standalone: true,
  templateUrl: './checkout-form.component.html',
  styleUrls: ['./checkout-form.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ]
})
export class CheckoutFormComponent {
  checkoutForm: FormGroup;
  isEditing: boolean = true; // Track if form is in edit mode or summary mode
  customerInfo?: CustomerInfo;

  constructor(private fb: FormBuilder, private stateService: StateService) {
    this.checkoutForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern('^\\+?[0-9]{7,15}$')]],
      street: ['', [Validators.required]],
      postalCode: ['', [Validators.required, Validators.pattern('^(LV-)?[0-9]{4}$')]],
      city: ['', [Validators.required]],
    });

    // Subscribe to the state to load customerInfo when available
    this.stateService.currentState.subscribe(state => {
      this.customerInfo = state.customerInfo;
      if (this.customerInfo) {
        this.populateForm(this.customerInfo);
        // Check if the form fields are empty. If they are, continue editing mode.
        if (this.isCustomerInfoEmpty(this.customerInfo)) {
          this.isEditing = true;
        } else {
          this.isEditing = false; // Show summary if customerInfo exists and has values
        }
      }
    });
  }

  // Check if the customer info is empty (i.e., all fields are blank)
  isCustomerInfoEmpty(customerInfo: CustomerInfo): boolean {
    return !customerInfo.firstName && !customerInfo.lastName && !customerInfo.phone && !customerInfo.street && !customerInfo.postalCode && !customerInfo.city;
  }

  // Populate the form with customer data if it exists
  populateForm(customerInfo: CustomerInfo) {
    this.checkoutForm.patchValue(customerInfo);
  }

  // On form submission, save the data and switch to summary mode
  onSubmit() {
    if (this.checkoutForm.valid) {
      this.stateService.updateCustomerInfo(this.checkoutForm.value);
      this.customerInfo = this.checkoutForm.value;
      this.isEditing = false; // Switch to summary mode after submitting
    } else {
      this.checkoutForm.markAllAsTouched();
    }
  }

  // Allow editing the form again
  editForm() {
    this.isEditing = true;
  }
}
