<div class="side-margins">
    <div class="text-2xl text-amber-950 font-bold my-3">
        Pirms lietošanas
    </div>
    <div class="mb-3">
        Lūdzu, rūpīgi izlasiet mūsu pārdošanas un atgriešanas noteikumus pirms produkta lietošanas.
        <br><br>
        No brīža, kad tiek atvērts ARSIKO iepakojums, jūs piekrītat mūsu pārdošanas un atgriešanas nosacījumiem.
        <br><br>
        Pārbaudiet, vai iepakojums nav bojāts no ārpuses. Ja tiek konstatēts bojājums, par to nekavējoties jāinformē
        piegādes kompānija pēc preces piegādes.
        <br><br>
        Ja saņemot preci, kāda no detaļām iztrūkst vai ir bojāta, 10 darba dienu laikā par to jāpaziņo ARSIKO.
        Preces atgriešanu varat veikt caur savu ARSIKO lietotāja kontu, norādot iemeslu "bojāta/nepilnīga prece".
        <br><br>
        Pārsniedzot noteikto atgriešanas termiņu, tiek zaudētas tiesības uz kompensāciju. Šī prasība ir saistīta ar
        transportēšanas apdrošināšanas noteikumiem un termiņiem, kas nosaka, cik ilgā laikā mums ir iespējams pieteikt
        pretenziju par bojātu sūtījumu.
    </div>

    <div class="text-2xl text-amber-950 font-bold my-3">
        Atgriešana
    </div>
    <div class="mb-3">
        Klients var pieprasīt preces atgriešanu 30 dienu laikā pēc pasūtījuma saņemšanas.
        <br><br>
        Pēc 30 dienu perioda preces atgriešana nav iespējama. Lūdzu, pārliecinieties, vai iegādātā detaļa ir pareiza,
        pat ja plānojat to izmantot vēlāk.
        <br><br>
        ARSIKO pārdod tikai jaunas detaļas, kas nozīmē, ka tās jāatgriež tādā pašā stāvoklī.
        <br><br>
        Mēs pieņemam tikai tīras un neizmantotas preces, kas tiek atgrieztas oriģinālajā iepakojumā ar redzamu preces
        numuru. Neaplīmējiet un neveiciet nekādas piezīmes uz oriģinālā iepakojuma vai pašas preces.
        <br><br>
        Ja precei tiek konstatētas jebkādas lietošanas pazīmes, kas ietekmē tās stāvokli, atgriešanas iespēja tiek
        noraidīta. Tas attiecas arī uz gadījumiem, kad klients pievieno piezīmi, ka detaļa tika uzstādīta tikai
        saderības pārbaudei. Šādos gadījumos prece var tikt atgriezta klientam vai, pēc abpusējas vienošanās,
        iznīcināta.
    </div>

    <div class="text-2xl text-amber-950 font-bold my-3">
        Kā notiek preces atgriešana?
    </div>
    <div class="mb-3">
        Lai atgrieztu preci, lūdzu, sazinieties ar mums pa e-pastu: info&#64;arsiko.com.
        <br><br>
        Atgriežot preci, pārliecinieties, ka tā ir oriģinālajā iepakojumā. Pārliecinieties, ka iepakojums ir piemērots
        preces izmēram un svaram, un pievienojiet amortizējošus materiālus, lai stabilizētu un pasargātu preci. Ja
        oriģinālais iepakojums ir bojāts, prece var tikt iesaiņota aizsargpapīrā vai plēvē.
    </div>

    <div class="text-2xl text-amber-950 font-bold my-3">
        Garantija
    </div>
    <div class="mb-3">
        Visiem produktiem, kas iegādāti ARSIKO, ir pieejama ražotāja garantija.
        <br><br>
        Jūs varat iesniegt garantijas pretenziju 24 mēnešu laikā no preces saņemšanas brīža.
        <br><br>
        Ja pretenzija tiek nodota ražotājam tālākai izskatīšanai, process var aizņemt līdz 90 dienām, kuru laikā tiek
        pārbaudīts, vai problēma ir saistīta ar ražošanas defektu.
        <br><br>
        Ja problēmu ar bojāto preci nav iespējams novērst, pirkums tiek anulēts. Šajā gadījumā mēs piedāvājam preci
        samainīt vai atgriezt samaksāto summu.
        <br><br>
        ARSIKO nesedz papildu izmaksas, kas radušās nepareiza pasūtījuma vai produkta gadījumā, kā arī nepareizas
        montāžas vai ekspluatācijas rezultātā vai gadījumos, kad ir aizkavēta piegāde.
    </div>

    <div class="text-2xl text-amber-950 font-bold my-3">
        Naudas atmaksa
    </div>
    <div class="mb-3">
        Apstiprinātas atgriešanas gadījumā ARSIKO veiks naudas atgriešanu 5 darba dienu laikā no preces saņemšanas
        brīža.
        <br><br>
        Atmaksa tiks veikta, izmantojot to pašu maksāšanas veidu, kuru izvēlējāties pirkuma laikā.
        <br><br>
        Pirms naudas atgriešanas produkti tiek pārskatīti un pārbaudīti.
    </div>
</div>