import { Component } from '@angular/core';

@Component({
  selector: 'app-return-policy',
  standalone: true,
  imports: [],
  templateUrl: './return-policy.component.html',
  styleUrl: './return-policy.component.scss'
})
export class ReturnPolicyComponent {

}
