<div class="flex justify-center items-center">
    <mat-card class="flex flex-col mt-36 w-1/4">
        <mat-card-title class="w-100 text-start pl-5 py-2 text-amber-950 title">Paroles atjaunošana</mat-card-title>
        <mat-card-content>
            <form (ngSubmit)="onSubmit()" class="flex flex-col">
                <mat-form-field appearance="fill">
                    <mat-label>E-pasts</mat-label>
                    <input matInput [(ngModel)]="model.username" name="username" required>
                </mat-form-field>

                <button mat-raised-button color="primary" type="submit">Iesniegt</button>

            </form>
        </mat-card-content>
    </mat-card>
</div>