import { Component } from '@angular/core';
import { BasketService } from '../../../services/basket/basket.service';
import { CheckoutFormComponent } from "./checkout-form/checkout-form.component";
import { DeliveryOptionsComponent } from "../delivery-options/delivery-options.component";
import { DeliveryMethodsComponent } from "./delivery-methods/delivery-methods.component";
import { PaymentComponent } from "./payment/payment.component";

@Component({
  selector: 'app-order',
  standalone: true,
  imports: [CheckoutFormComponent, DeliveryOptionsComponent, DeliveryMethodsComponent, PaymentComponent],
  templateUrl: './order.component.html',
  styleUrl: './order.component.scss',
})
export class OrderComponent {
  constructor(private basketService: BasketService) {
    this.basketService.setTitle('Pirkuma noformēšana');
  }

  ngOnInit() {}
}
