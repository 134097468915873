import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Category } from '../../models/category.model';
import { TecdocService } from '../../services/tecdoc/tecdoc.service';
import { CategoryGridComponent } from '../shop/category-grid/category-grid.component';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { StateService } from '../../services/state/state.service';

interface FacetGroup {
  parentId: string;
  data: any;
}

export interface CategoryNode {
  assemblyGroupNodeId: number;
  assemblyGroupName: string;
  assemblyGroupType: string;
  parentNodeId?: number;
  children?: CategoryNode[];
  count?: number;
}

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SideNavComponent,
    CategoryGridComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  categories: Category[] = [];
  categoryTree: CategoryNode[] = [];
  loading: boolean = false;
  state: any;

  constructor(
    private tecdocService: TecdocService,
    private stateService: StateService
  ) {}

  ngOnInit() {
    this.stateService.currentState.subscribe((state) => {
      this.state = state;
    });
    this.loading = true;
    this.tecdocService.getCategories().subscribe({
      next: (response) => {
        this.loading = false;
        this.categoryTree = response;
      },
      error: (err) => {
        this.loading = false;
        console.log('Error while getting tecdoc data', err);
      },
    });
  }

  findCategoryById(root: CategoryNode[], id: number): CategoryNode | null {
    for (let node of root) {
      if (node.assemblyGroupNodeId === id) {
        return node;
      }
      const found = this.findCategoryById(node.children || [], id);
      if (found) {
        return found;
      }
    }
    return null;
  }
}
