import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PriceService {
  private baseUrl = `${environment.apiBaseUrl}/prices`;

  constructor(private http: HttpClient) {}

  getPricesByNames(names: string[]) {
    return this.http.post<any[]>(`${this.baseUrl}/findManyByNames`, { names });
  }

  getAPEPrice(name: string) {
    return this.http.get<any[]>(`${this.baseUrl}/ape/${name}`);
  }

  getAPEPrices(names: string[]) {
    return this.http.post<any[]>(`${this.baseUrl}/ape`, {
      supplierProductCodes: names,
    });
  }
}
