import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Item } from '../../models/item.model';
import { Category } from '../../constants';

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  private baseUrl = `${environment.apiBaseUrl}`; // Adjust to your backend URL

  constructor(private http: HttpClient) {}

  getItems(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/items`);
  }

  getItem(id: number): Observable<Item> {
    return this.http.get<Item>(`${this.baseUrl}/shop/items/${id}`);
  }

  getCategories(): Observable<Category[]> {
    return this.http.get<any[]>(`${this.baseUrl}/shop/categoryList`);
  }

  getCategory(id: number): Observable<Category> {
    return this.http.get<Category>(`${this.baseUrl}/shop/category/${id}`);
  }

  getItemsByCategory(id: number): Observable<Item[]> {
    return this.http.get<Item[]>(`${this.baseUrl}/shop/itemsByCategory/${id}`);
  }
}
