<div class="basket-items shadow-md bg-neutral-50 rounded-md" *ngIf="basketItems.length">
    <div *ngFor="let item of basketItems; index as i">
        <div class="basket-item">
            <div class="item-image">
                <img [src]="item.imageUrl" alt="{{ item.name }}" />
            </div>
            <div class="item-details">
                <h3>{{ item.name }}</h3>
                <p class="auto-specifics">ID: {{ item.productId }}</p>
                <p class="car-model">{{item.car}}</p>
                <p class="pickup-date">{{ deliveryOption.name + ': ' + deliveryOption.date }}</p>
                <a (click)="removeItem(item.productId)" class="hover:underline">Dzēst</a>
            </div>
            <div class="item-quantity-price">
                <div class="flex items-center justify-center space-x-2 mb-4">
                    <input #quantityInput type="number" (click)="updateQuantity(item.productId, $event)"
                        class="w-12 text-center border border-gray-300 rounded" [value]="item.quantity" min="1" />
                </div>
                <div class="item-price">
                    <p>{{ item.price * item.quantity| currency: 'EUR' }}</p>
                    <span>{{ item.price | currency: 'EUR' }}/gab.</span>
                </div>
            </div>
        </div>
        <mat-divider class="!mt-2" *ngIf="i < basketItems.length - 1"></mat-divider>
    </div>
</div>

<div *ngIf="!basketItems.length">Grozs ir tukšs</div>