export function extractYear(dateString: string | undefined): string {
  if (dateString == undefined) return '';
  const year = dateString.split('-')[0];
  return year;
}

export function extractYearShort(dateString: string | undefined): string {
  if (dateString == undefined) return '';
  const year = dateString.split('-')[0];
  return year.slice(-2);
}

  // Function to format date as 'Diena, dd.MM.yy'
  export function formatDate(date: Date): string {
    const daysOfWeek = [
      'Svētdiena',
      'Pirmdien',
      'Otrdien',
      'Trešdien',
      'Ceturtdien',
      'Piektdien',
      'Sestdien',
    ];
    const day = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);

    return `${day}, ${dayOfMonth}.${month}.${year}`;
  }

  export function getClosestDate(): string {
    return formatDate(new Date());
  }