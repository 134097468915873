<div class="side-margins">
    <div class="text-2xl text-amber-950 font-bold my-3">
        Par mums
    </div>
    <div class="mb-3 text-justify">Mūsu uzņēmums, "ARSIKO", dibināts ar mērķi nodrošināt plašu augstas kvalitātes vieglo,kravas
        automašīnu un citas tehnikas rezerves daļu klāstu Latvijas tirgū. Mūsu pieredze un aizrautība par tehnikas
        nozari ir pamatā tam, kā mēs izvēlamies un piedāvājam produktus mūsu klientiem.
        <br>
        <br>Mēs saprotam, ka uzticamība un izturība ir būtiski faktori auto rezerves daļām, tādēļ katrs produkts mūsu
        katalogā ir rūpīgi atlasīts, lai atbilstu augstākajiem nozares standartiem. "ARSIKO" komanda ir cieši
        sadarbojusies ar vadošajiem ražotājiem un piegādātājiem visā pasaulē, lai nodrošinātu, ka mūsu sortimentā ir
        iekļautas tikai labākās un uzticamākās rezerves daļas.
        <br>
        <br>Mūsu misija ir piedāvāt ne tikai plašu produkta klāstu, bet arī profesionālu konsultāciju un atbalstu, lai
        palīdzētu mūsu klientiem izvēlēties vispiemērotākās rezerves daļas viņu vajadzībām. Mēs lepojamies ar mūsu
        klientu apkalpošanas komandu, kas vienmēr ir gatava sniegt atbalstu, sākot no produkta izvēles līdz piegādei un
        aiz tās.
        <br>
        <br>
        "ARSIKO" vērtības ir balstītas uz godīgumu, uzticību un klientu apmierinātību. Mēs uzskatām, ka ilgtermiņa
        attiecības ar klientiem un piegādātājiem veido uzņēmuma pamatu. Tādēļ mēs esam apņēmušies nodrošināt augstas
        kvalitātes produktus un pakalpojumus, kas atbilst mūsu klientu gaidām un vajadzībām.
        <br>
        <br>
        Mēs aicinām jūs apmeklēt mūsu tiešsaistes veikalu un iepazīties ar plašo rezerves daļu klāstu. Ja jums ir
        jautājumi vai nepieciešama palīdzība, lūdzu, nevilcinieties sazināties ar mūsu klientu apkalpošanas komandu.
        Paldies, ka izvēlējāties "ARSIKO" par savu uzticamo partneri savas tehnikas rezerves daļu jomā.
    </div>

</div>