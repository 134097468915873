import { Component, Input } from '@angular/core';
import { Category } from '../../../models/category.model';
import { CategoryNode } from '../../home/home.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { State, StateService } from '../../../services/state/state.service';

@Component({
  selector: 'app-category-grid',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './category-grid.component.html',
  styleUrl: './category-grid.component.scss',
})
export class CategoryGridComponent {
  @Input('categories')
  categoryTree!: CategoryNode[];
  @Input('isSub')
  isSub: boolean = false;
  @Input('isModel')
  isModel: boolean = false;
  @Input('isDetails')
  isDetails: boolean = false;

  getLink(category: CategoryNode): string {
    const base = 'category/' + category.assemblyGroupNodeId;
    if (this.isDetails) return '../../../' + base;
    if (this.isSub) return '../../' + base;
    if (!this.isModel) return 'catalogue/' + base;
    return base;
  }

}
