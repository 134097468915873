<div class="flex flex-col h-auto min-h-screen bg-neutral-50">
    <div class="flex flex-col h-full flex-grow">
        <header> <app-top-menu class="w-full"></app-top-menu> </header>

        <main class="flex-grow h-full w-auto" *ngIf="!maintenance">
            <router-outlet class="w-full h-full"></router-outlet>
        </main>
        <div class="maintenance-message" *ngIf="maintenance">
            Vietne pašlaik tiek atjaunināta, lūdzu, pārbaudiet vēlāk.
        </div>
    </div>
    <footer>
        <app-footer></app-footer>
    </footer>
</div><app-notification></app-notification>